<template>
    <a-modal v-model="show" title="编辑通知公告" :width="800" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
        <a-form-model ref="formRef" :model="formData" :rules="formRule">
            <a-row :gutter="15">
                <a-col span="14">
                    <a-form-model-item label="标题" label-position="top" prop="title">
                        <a-input v-model="formData.title" placeholder="请输入标题"></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col span="6">
                    <a-form-model-item label="类别" label-position="top" prop="categoryId">
                        <a-select v-model="formData.categoryId" placeholder="请选择类别">
                            <a-select-option :value="item.categoryId" v-for="item in category" :key="item.categoryId">{{
                                item.name }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col span="4">
                    <a-form-model-item label="发布状态" label-position="top" prop="status">
                        <a-select v-model="formData.status">
                            <a-select-option value="未发布">未发布</a-select-option>
                            <a-select-option value="已发布">已发布</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="15">
                <a-col span="6">
                    <a-form-model-item label="生效日期" label-position="top" prop="startDate">
                        <a-date-picker v-model="formData.startDate" placeholder="请选择生效日期" />
                    </a-form-model-item>
                </a-col>
                <a-col span="6">
                    <a-form-model-item label="失效日期" label-position="top" prop="overDate">
                        <a-date-picker v-model="formData.overDate" placeholder="请选择失效日期" />
                    </a-form-model-item>
                </a-col>
                <a-col span="8" v-if="getOrganId() < 1">
                    <a-form-model-item label="发布单位" label-position="top" prop="organId">
                        <a-select v-model="formData.organId" style="width:100%" @change="organIdChange">
                            <a-select-option :value="organ.organId" v-for="(organ, index) in getOrganList()" :key="index">{{
                                organ.name }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>

                <a-col span="4">
                    <a-form-model-item label="是否置顶" label-position="top" prop="isTop">
                        <a-switch v-model="formData.isTop"> </a-switch>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="15">
                <a-col span="24">
                    <a-form-model-item label="发布范围(部门)" label-position="top" prop="scopeDepIds">
                        <select-depart v-model="formData.scopeDepIds" :organId="formData.organId"
                            :multiple="true"></select-depart>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="15">
                <a-col span="12">
                    <a-form-model-item label="发布范围(角色)" label-position="top" prop="scopeRoleIds">
                        <select-role v-model="formData.scopeRoleIds" :organId="formData.organId"
                            :multiple="true"></select-role>
                    </a-form-model-item>
                </a-col>
                <a-col span="12">
                    <a-form-model-item label="发布范围(人员)" label-position="top" prop="scopeStaffIds">
                        <select-staff ref="staff" v-model="formData.scopeStaffIds" :names="formData.staffs"
                            :organId="formData.organId" multiple></select-staff>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-form-model-item prop="content">
                <editor v-model="formData.content" />
            </a-form-model-item>
            <a-form-model-item prop="attachment">
                <attach-upload v-model="formData.attachment"></attach-upload>
            </a-form-model-item>

        </a-form-model>
        <a-spin size="large" fix v-if="loading" />
    </a-modal>
</template>

<script>
import request from "@/config/request";
import utils from "@/common/utils";
import apiUtil from "@/common/apiUtil";
import attachUpload from '@/components/common/attach-upload.vue';
export default {
    components: { attachUpload },
    data() {
        return {
            show: false,
            loading: false,
            formData: {
                noticeId: '',
                organId: this.organId,
                categoryId: '',
                title: "",
                content: "",
                isTop: false,
                status: "已发布",
                startDate: '',
                overDate: '',
                scopeDepIds: [],
                scopeRoleIds: [],
                scopeStaffIds: [],
                attachment: []
            },
            formRule: {
                categoryId: [{ required: true, message: '请选择分类' }],
                title: [{ required: true, message: '请填写标题' }],
                content: [{ required: true, message: '请填写内容' }],
                organId: [{ required: true, message: '请选择发布单位' }],
            }
        }
    },
    methods: {
        ...apiUtil,
        organIdChange() {
            this.formData.scopeDepIds = [];
            this.formData.scopeRoleIds = [];
            this.formData.scopeStaffIds = [];
            this.formData.staffs = '';
        },
        _show(form) {
            this.show = true;
            this.$nextTick(() => {
                this.$refs.formRef.resetFields();
                if (form) {
                    this.formData = JSON.parse(JSON.stringify(form));
                } else {
                    this.formData.noticeId = '';
                    this.formData.content = '';
                }
            })
        },
        _close() {
            this.$refs.formRef.resetFields();
            this.show = false;
            this.$emit('close')
        },
        doSubmit() {
            this.$refs.formRef.validate((valid) => {
                if (valid) {
                    // console.log(this.formData.managerDepIds); return;
                    utils.showSpin();
                    request.post('/platform/notice/save', this.formData).then(ret => {
                        this.$refs.formRef.resetFields();
                        // console.log(ret)
                        this.show = false;
                        if (ret.code == 200) {
                            utils.success('操作成功！').then(() => {
                                this.show = false;
                                this.$emit('success');
                            })
                        }
                    })
                }
            })
        }
    },
    props: {
        category: {
            type: Array,
            default: () => {
                return [];
            }
        },
        organId: {
            type: Number | String,
            default: () => {
                return apiUtil.getOrganId();
            }
        }
    },
    watch: {
        organId(v) {
            this.formData.organId = v;
        }
    }
}
</script>

<style scoped>
.demo-drawer-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
}
</style>
